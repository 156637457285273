<template lang="pug">
aside.tile-line
  .tile-line__cont.cont
    h2.tile-line__title.title(v-if="blockName") {{ blockName }}
    template(v-if="products.length")
      .tile-line__list
        tile-big(
          v-for="product in products",
          :key="product.id",
          :slug="product.slug",
          :price="product.price_current",
          :price-old="product.price_old",
          :name="product.name",
          :image="product.image"
          :is-hit="product.is_designers_choice",
          :is-new="product.is_new"
          :in-stock="product.stock",
          :is-chiedo-product="product.is_chiedo_product"
          :is-available-for-cart="product.is_available_for_cart"
        )
      .tile-line__btn-box(v-if="moreRoute")
        router-link.tile-line__button.button-white(:to="moreRoute") {{ moreText }}
      .tile-line__pagination(v-if="pages > 1")
        pagination(
            :current-page = "currentPage",
            :pages = "pages",
            :is-loading-button="isLoadingButton",
            @show-more="showMore",
            @update-page="updatePage"
          )
    p.tile-line__info.text-big(v-else) По вашим параметрам ничего не найдено
</template>

<script>
import '@/assets/styles/components/tile-line.sass'
import TileBig from './TileBig'
import Pagination from '../Pagination'

export default {
  name: 'TileLine',
  props: {
    isLoading: Boolean,
    blockName: { type: String },
    products: { type: Array, default () { return [] } },
    moreText: { type: String, default: 'Смотреть все' },
    moreRoute: { type: Object, default: null },
    pages: { type: Number },
    currentPage: { type: Number },
    isLoadingButton: Boolean
  },
  components: { Pagination, TileBig },
  methods: {
    showMore (page) {
      this.$emit('show-more', page)
    },
    updatePage (path) {
      this.$emit('update-page', path)
    }
  }
}
</script>
