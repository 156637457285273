<template lang='pug'>
.sticky-contacts(:class="{'sticky-contacts_active' : isOpen}")
  button.sticky-contacts__button(type="button", title="Контакты", @click="toggleContacts")
  .sticky-contacts__list
      a.sticky-contacts__link(
        v-for="(item, i) in contacts",
        :key="i"
        :class="`sticky-contacts__link_${item.mod}`",
        :href="item.link",
        :title="item.title",
        target='_blank',
        rel="nofollow noopener"
      )
</template>

<script>
import '@/assets/styles/components/sticky-contacts.sass'
import { contacts } from '../../assets/scripts/variables'
export default {
  name: 'TheStickyContacts',
  data () {
    return {
      contacts,
      isOpen: false
    }
  },
  methods: {
    toggleContacts () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>
