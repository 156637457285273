<template lang="pug">
article.informer
  .informer__cont.cont
    .informer__area
      .informer__slider(v-if="bigBanners.length")
        router-link.informer__slider-item(:to="{ path: slide.url }")
          picture.informer__slider-picture
            source(:srcset="`${slide.image.w404} 1x, ${slide.image.w808} 2x`", type="image/webp", media="(max-width: 520px)")
            source(:srcset="`${slide.image.w702} 1x, ${slide.image.w1053} 2x`", type="image/webp", media="(max-width: 700px)")
            source(:srcset="`${slide.image.w852} 1x, ${slide.image.w1278} 1.5x`", type="image/webp", media="(max-width: 1240px)")
            source(:srcset="`${slide.image.w942} 1x, ${slide.image.w1413} 1.5x`", type="image/webp")
            source(:srcset="`${slide.image.j942} 1x, ${slide.image.j1413} 1.5x`")
            img.informer__slider-image(:src="`${slide.image.j942}`", :alt="`${slide.text} - фото`")
          .informer__slider-box
            span.informer__slider-tag {{ slide.tag }}
            h2.informer__title.title {{ slide.text }}
            .informer__button.text {{ slide.button }}
        ul.informer__slider-nav(v-if="bigBanners.length > 1")
          li.informer__slider-nav-item(:class="{'informer__slider-nav-item_active': i === currentSlideIndex }", v-for="i in bigBanners", :key="i", @click="changeSlide(i)")
      .informer__slider(v-else)
        .informer__slider-item.informer__slider-item_preload
      .informer__list(v-if="smallBanners.length")
        router-link.informer__item(v-for="(slide, i) in smallBanners", :key="i", :style="`background-color: ${slide.color}`", :to="slide.url")
          .informer__item-box
            span.informer__item-tag {{ slide.tag }}
            span.informer__headline {{ slide.text }}
          picture.informer__picture
            source(:srcset="`${slide.image.w83} 1x, ${slide.image.w166} 2x`", type="image/webp", media="(max-width: 700px)")
            source(:srcset="`${slide.image.w90} 1x, ${slide.image.w180} 2x`", type="image/webp", media="(max-width: 1240px)")
            source(:srcset="`${slide.image.w137} 1x, ${slide.image.w206} 1.5x`", type="image/webp")
            source(:srcset="`${slide.image.j137} 1x, ${slide.image.j206} 1.5x`")
            img.informer__image(:src="`${slide.image.j137}`", :alt="`${slide.text} - фото`")
      .informer__list(v-else)
        .informer__item.informer__item_preload
        .informer__item.informer__item_preload
</template>

<script>
import '@/assets/styles/components/informer.sass'

export default {
  name: 'Informer',
  props: {
    bigBanners: Array,
    smallBanners: Array
  },
  data () {
    return {
      currentSlideIndex: 0
    }
  },
  methods: {
    changeSlide (index) {
      this.currentSlideIndex = index
    }
  },
  computed: {
    slide () {
      return this.bigBanners[this.currentSlideIndex]
    }
  }
}
</script>
