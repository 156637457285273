export const phone = {
  mask: '+7 922 955 87 77',
  link: 'tel:+79229558777'
}

export const mail = {
  mask: 'mail@chiedohome.com',
  link: 'mailto:mail@chiedohome.com'
}

export const url = 'https://chiedohome.com'

export const worktime = {
  weekdays: 'Будни с 8:00 до 17:00',
  weekends: 'Выходные с 11:00 до 15:00',
  mask: 'в будни с 8:00 до 17:00 или в выходные с 11:00 до 15:00'
}

export const addresses = [
  {
    name: 'Офис продаж',
    address: '610002, Россия, Кировская обл, Киров, Орловская, 4Г',
    worktime: ['Пн-Пт: с 8:00 до 17:00 (мск)', 'Сб и Вс: с 11:00 до 15:00 (мск)'],
    coords: [58.595619, 49.689699]
  }, {
    name: 'Склад в Москве',
    address: 'Москва, Сормовский проезд, 7А',
    worktime: ['Пн-Чт: с 9:00 до 17:00 (мск)', 'Пт: с 9:00 до 16:00 (мск)'],
    coords: [55.709348, 37.798161]
  }
]

export const contacts = [
  {
    title: 'WhatsApp',
    link: 'https://wa.me/79229234577',
    mod: 'wa'
  }
]
