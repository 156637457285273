import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueYandexMetrika from 'vue-v3-yandex-metrika'

const app = createApp(App)
app.use(router)
if (!process.env.VUE_APP_METRIKA_NOT_CONNECT) {
  app.use(VueYandexMetrika, {
    id: 87676786,
    router: router,
    env: process.env.NODE_ENV
  })
}

app.mount('#app')
