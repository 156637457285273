<template lang="pug">
teleport(to='head')
  title {{ titlePage }}
  template(v-if="description")
    meta(name="description", :content="description")
    meta(property='og:description', :content="description")
  link(rel="canonical", :href="`${url}${this.$route.path}`")
  component(v-if="og", :is="'script'", v-html="og", type="application/ld+json")
</template>

<script>
import { url } from '../../assets/scripts/variables'

export default {
  name: 'HeadMeta',
  props: {
    titlePage: {
      type: String,
      required: true
    },
    description: String,
    og: null
  },
  data () {
    return {
      url
    }
  }
}
</script>
