import req from './request'
import Storage from './Storage'

const url = process.env.VUE_APP_API

export async function login (email, password) {
  const res = await req.post(`${url}cabinet/login`, { email, password })
  Storage.user = res.user
  return res
}

export async function logout () {
  return req.post(`${url}cabinet/logout`)
}

export async function registration (email, password, name, phone) {
  return req.post(`${url}cabinet/register`, { email, password, name, phone })
}

export async function verifyEmail (token) {
  return req.post(`${url}cabinet/verify-email`, { token })
}

export async function passwordForgot (email) {
  return req.post(`${url}cabinet/password-forgot`, { email })
}

export async function getUser () {
  const data = Storage.user
  if (data) return data
  const res = await req.post(`${url}cabinet/user-info`)
  Storage.user = res
  return res
}

export async function refreshUser () {
  const data = await req.post(`${url}cabinet/user-info`)
  Storage.user = data
  return data
}

export async function updateUser (email, name, phone, oldPassword, password) {
  const res = await req.post(`${url}cabinet/update-user`, { email, name, phone, oldPassword, password })
  Storage.user = res.user
  return res
}

export async function getBasketHistory () {
  return req.post(`${url}cabinet/orders`)
}

export async function getMenu () {
  const data = Storage.menu
  if (data) return data
  const res = await req.post(`${url}menu`)
  Storage.menu = res
  return res
}

export async function getCategoriesPopular () {
  const data = Storage.categoriesPopular
  if (data) return data
  const res = await req.post(`${url}category`, { is_popular: 1, limit: 6 })
  Storage.categoriesPopular = res
  return res
}

export async function getProductsInStockForTheHome () {
  const data = Storage.prodsSaleForTheHome
  if (data) return data
  const res = await req.post(`${url}products/some`, { is_stock: 1 })
  Storage.prodsSaleForTheHome = res
  return res
}

export async function getProductsDesignersChoiceForTheHome () {
  const data = Storage.prodsPopForTheHome
  if (data) return data
  const res = await req.post(`${url}products/some`, { designers_choice: 1 })
  Storage.prodsPopForTheHome = res
  return res
}

export async function getProductsInStock (page = 1, sort = '', filters = {}, limit = 20) {
  if (!sort && !Object.keys(filters).length) {
    const data = Storage.getProdsInStock(page)
    if (data) return data
    const res = await req.post(`${url}products`, { is_stock: true, page, sort, f: filters, limit })
    if (res.status !== 'error') Storage.setProdsInStock(page, res)
    return res
  } else {
    return await req.post(`${url}products`, { is_stock: true, page, sort, f: filters, limit })
  }
}

export async function getProductsDesignersChoice (page = 1, sort = '', filters = {}, limit = 20) {
  if (!sort && !Object.keys(filters).length) {
    const data = Storage.getProdsDesignersChoice(page)
    if (data) return data
    const res = await req.post(`${url}products`, { designers_choice: true, page, sort, f: filters, limit })
    if (res.status !== 'error') Storage.setProdsDesignersChoice(page, res)
    return res
  } else {
    return await req.post(`${url}products`, { designers_choice: true, page, sort, f: filters, limit })
  }
}

export async function getProductsPopularForBasket (page = 1, sort = '', filters = {}, limit = 12) {
  const data = Storage.prodsPopularForBasket
  if (data) return data
  const res = await req.post(`${url}products/some`, { designers_choice: 1 })
  Storage.prodsPopularForBasket = res
  return res
}

export async function getProduct (slug) {
  const data = Storage.getProduct(slug)
  if (data) return data
  const res = await req.post(`${url}product`, { slug })
  if (res.status !== 'error') {
    Storage.setProduct(slug, res)
  }
  return res
}

export async function refreshProduct (slug) {
  const res = await req.post(`${url}product`, { slug })
  if (res.status !== 'error') {
    Storage.setProduct(slug, res)
  }
  return res
}

export async function getCategory (slug) {
  const data = Storage.getCategory(slug)
  if (data) return data
  const res = await req.post(`${url}category`, { slug })
  if (res.status !== 'error') Storage.setCategory(slug, res)
  return res
}

export async function getProductsCategory (slug, page = 1, sort = '', filters = {}, limit = 20) {
  if (!sort && !Object.keys(filters).length) {
    const data = Storage.getProductsCategory(slug, page)
    if (data) return data
    const res = await req.post(`${url}products`, { category_slug: slug, page, sort, f: filters, limit })
    if (res.status !== 'error') Storage.setProductsCategory(slug, page, res)
    return res
  } else {
    return await req.post(`${url}products`, { category_slug: slug, page, sort, f: filters, limit })
  }
}

export async function getProductsSubcategory (slug, page = 1, sort = '', filters = {}, limit = 20) {
  if (!sort && !Object.keys(filters).length) {
    const data = Storage.getProductsSubcategory(slug, page)
    if (data) return data
    const res = await req.post(`${url}products`, { subcategory_slug: slug, page, sort, f: filters, limit })
    if (res.status !== 'error') Storage.setProductsSubcategory(slug, page, res)
    return res
  } else {
    return await req.post(`${url}products`, { subcategory_slug: slug, page, sort, f: filters, limit })
  }
}

export async function getBanners () {
  const data = Storage.banners
  if (data) return data
  const res = await req.post(`${url}banners`, { position: 'top', limit: 10 })
  Storage.banners = res
  return res
}

export async function getMoreProducts (slug) {
  const data = Storage.getMoreProducts(slug)
  if (data) return data
  const res = await req.post(`${url}product/more`, { slug })
  if (res.status !== 'error') Storage.setMoreProducts(slug, res)
  return res
}

export async function getSearchResults (query, page = 1, sort = '', filters = {}) {
  return await req.post(`${url}products`, { search: query, page, sort, f: filters })
}

export async function getBasket () {
  const data = Storage.basket
  if (data) return data
  const res = await req.post(`${url}cart/get`)
  Storage.basket = res
  return res
}

export async function refreshBasket () {
  const res = await req.post(`${url}cart/get`)
  Storage.basket = res
  return res
}

export function addProduct (id, count = 1) {
  return req.post(`${url}cart/set`, { id, count })
}

export function setProductCount (id, count) {
  return req.post(`${url}cart/set`, { id, count })
}

export async function removeProduct (id) {
  return req.post(`${url}cart/set`, { id, count: 0 })
}

export async function basketCheckout (products, name, email, phone, isAgree) {
  return req.post(`${url}cart/checkout`, { products, name, email, phone, isAgree })
}

export function sendProductForm (slug, phone) {
  return req.post(`${url}product/form`, { slug, phone })
}

export function sendProductAvailabilityForm (slug, email) {
  return req.post(`${url}product/inform-availability`, { slug, email })
}

export function getSearchHints (query) {
  return req.post(`${url}search`, { query })
}
