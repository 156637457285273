<template lang="pug">
article.category-tile(v-if="categories.length")
  .category-tile__cont.cont
    h2.category-tile__title.title Популярные категории
    .category-tile__list
      router-link.category-tile__item(
            v-for="category in categories",
            :key="category.id",
            :to="{ name: 'Catalog', params: { slug: category.slug } }"
          )
        picture.category-tile__picture(v-if="category.image")
          source(:srcset="`${category.image.w94} 1x, ${category.image.w188} 2x`", type="image/webp", media="(max-width: 520px)")
          source(:srcset="`${category.image.w120} 1x, ${category.image.w240} 2x`", type="image/webp", media="(max-width: 1240px)")
          source(:srcset="`${category.image.w120} 1x, ${category.image.w180} 1.5x`", type="image/webp", media="(max-width: 1700px)")
          source(:srcset="`${category.image.w190} 1x, ${category.image.w285} 1.5x`", type="image/webp")
          source(:srcset="`${category.image.j190} 1x, ${category.image.j285} 1.5x`")
          img.category-tile__image(:src="category.image.j190", :alt="category.name", loading="lazy")
        span.category-tile__name {{ category.name }}
article.category-tile(v-else)
  .category-tile__cont.cont
    h2.category-tile__title.title Популярные категории
    .category-tile__list
      .category-tile__item.category-tile__item_preload
      .category-tile__item.category-tile__item_preload
      .category-tile__item.category-tile__item_preload
      .category-tile__item.category-tile__item_preload
      .category-tile__item.category-tile__item_preload
      .category-tile__item.category-tile__item_preload
</template>

<script>
import '@/assets/styles/components/category-tile.sass'

export default {
  name: 'CategoryTile',
  props: {
    categories: Array
  }
}
</script>
