<template lang="pug">
the-header
.wrapper
  router-view
the-footer
the-sticky-contacts
</template>

<script>
import '@/assets/styles/tools/app.sass'
import '@/assets/styles/tools/fonts.sass'
import '@/assets/styles/tools/framework.sass'
import '@/assets/styles/tools/links.sass'
import '@/assets/styles/tools/buttons.sass'
import '@/assets/styles/tools/content.sass'
import '@/assets/styles/tools/crumbs.sass'
import '@/assets/styles/tools/inputs.sass'

import TheHeader from './components/_layout/TheHeader'
import TheFooter from './components/_layout/TheFooter'
import TheStickyContacts from './components/_layout/TheStickyContacts'

export default {
  name: 'App',
  components: { TheStickyContacts, TheHeader, TheFooter }
}
</script>
