<template lang="pug">
article.informer-line(:class="mod ? `informer-line_${mod}`: ''")
  .informer-line__cont.cont
    .informer-line__area
      router-link.informer-line__item(v-for="(slide, i) in banners", :key="i", :style="`background-color: ${slide.color}`", :to="{path: slide.url}")
        .informer-line__item-box
          .informer-line__item-tag {{slide.tag}}
          h3.informer-line__headline.headline {{slide.text}}
        picture.informer-line__picture
          source(:srcset="`${slide.image.w83} 1x, ${slide.image.w166} 2x`" type="image/webp" media="(max-width: 700px)")
          source(:srcset="`${slide.image.w90} 1x, ${slide.image.w180} 2x`" type="image/webp" media="(max-width: 1240px)")
          source(:srcset="`${slide.image.w137} 1x, ${slide.image.w206} 1.5x`", type="image/webp")
          source(:srcset="`${slide.image.j137} 1x, ${slide.image.j206} 1.5x`")
          img.informer-line__image(:src="`${slide.image.j137}`" loading="lazy")
</template>

<script>

import '@/assets/styles/components/informer-line.sass'

export default {
  name: 'InformerLine',
  props: {
    banners: Array,
    mod: {
      type: String
    }
  }
}
</script>
