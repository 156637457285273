<template lang="pug">
header.header
  .header__cont.cont
    .header__cap
      .header__box(:class="{'header__box_search-open': isShowMobileSearch }")
        .header__burger(@click="isShowMobileMenu = !isShowMobileMenu", :class="{'header__burger_close' : isShowMobileMenu}")
          .header__burger-line
        span.header__logo(v-if="$route.name === 'Home'" )
          img.header__logo-image(src="/images/logo.svg", alt="logo")
        router-link.header__logo(v-else, :to="{ name: 'Home' }")
          img.header__logo-image(src="/images/logo.svg", alt="logo")
        form.header__search(@submit.prevent="goToSearchResultsPage", ref="headerSearch")
          label.header__search-label
            button.header__search-submit(type="submit")
            input.header__search-input.input(
                type="search",
                placeholder="Поиск",
                v-model="query",
                @input="getAutocompleteResults",
                @focus="eventFocusSearch"
                ref="input"
              )
            button.header__search-close-btn(type="button", v-if="query || isShowMobileSearch", @click="clearInput")
          .header__search-list(v-if="isShowSearchHints && (searchCategories.length || searchProducts.length)" )
            .header__search-cont
              ul.header__search-category-list(v-if="searchCategories.length")
                li.header__search-category-item(v-for="category in searchCategories", :key="category.slug")
                  router-link.header__search-category-link.link_black-orange(
                      :to="{name: 'Catalog', params: {slug: category.slug}}"
                    ) {{category.name}}
              ul.header__search-product-list(v-if="searchProducts.length")
                li.header__search-product-item(v-for="product in searchProducts", :key="product.slug")
                  router-link.header__search-product-link(
                    :to="{name: 'Product', params: {slug: product.slug}}"
                  )
                    .header__search-product-info
                      span.header__search-product-name.text {{product.name}}
                      span.header__search-product-description.text-small {{product.description}}
                    picture.header__search-product-picture
                      source(:srcset="`${product.image.w57} 1x, ${product.image.w114} 2x`" type="image/webp" media="(max-width: 1240px)")
                      source(:srcset="`${product.image.w57} 1x, ${product.image.w86} 1.5x`" type="image/webp" )
                      source(:srcset="`${product.image.j57} 1x, ${product.image.j86} 1.5x`")
                      img.header__search-product-image(:src="`${product.image.j57}`" alt="" loading="lazy")
        a.header__phone.link_black-orange(:href="phone.link") {{ phone.mask }}
        .header__search-mobile-btn(@click="openMobileSearch", ref="searchMobileBtn")
        a.header__phone-mobile(:href="phone.link")
        span.header__login(v-if="$route.name === 'UserInfo'")
        router-link.header__login(v-else, :to="{name: 'UserInfo'}")
        span.header__cart(v-if="$route.name === 'Basket'")
          span.header__cart-amount(
            v-if="basket && basket.total_quantity"
            :class="{'header__cart-amount_many' : basket.total_quantity > 99}") {{basket.total_quantity}}
        router-link.header__cart(v-else, :to="{name: 'Basket'}")
          span.header__cart-amount(
            v-if="basket && basket.total_quantity"
            :class="{'header__cart-amount_many' : basket.total_quantity > 99}") {{basket.total_quantity}}
    .header__panel(:class="{'header__panel_active' : isShowMobileMenu}")
      nav.header__menu
        span.header__menu-login(v-if="$route.name === 'Login'") Мой кабинет
        router-link.header__menu-login(v-else, :to="{name: 'Login'}") Мой кабинет
        .header__list(v-if="categories && categories.length")
          template(v-for="(category, i) in categories", :key="i")
            span.header__link.text(v-if="$route.params.slug === category.slug" ) {{ category.name }}
            router-link.header__link.link_black-orange.text(
                v-else-if="category.type === 'category'"
                :to="{ name: 'Catalog', params: { slug: category.slug }}",
                @click= "isShowMobileMenu = false"
              ) {{ category.name }}
            //router-link.header__link.link_black-orange.text(
            //    v-else-if="category.type === 'product'"
            //    :to="{ name: 'Product', params: { slug: category.slug }}",
            //    @click= "isShowMobileMenu = false",
            //    active-class="header__link_active"
            //  ) {{ category.name }}
            a.header__link.link_black-orange.text(v-else, :href="category.static_link") {{ category.name }}

          //span.header__link.text(v-if="$route.name === 'Actions'") Акции
          //router-link.header__link.link_black-orange.text.fc_red(v-else, :to="{ name: 'Actions' }", @click= "isShowMobileMenu = false") Акции
        .header__list.header__list_static
          template(v-for="(item, index) in list", :key="index")
            span.header__link.text(v-if="$route.name === item.link" ) {{ item.text }}
            router-link.header__link.link_black-orange.text(v-else, :to="{ name: item.link }", @click= "isShowMobileMenu = false") {{ item.text }}
        .header__mobile-contacts
          a.header__mobile-phone-text(:href="phone.link") {{phone.mask}}
  .header__layer(v-if="isShowMobileMenu || isShowSearchHints", @click="isShowMobileMenu=false", :class="{'header__layer_active' : isShowMobileMenu || isShowSearchHints}" )
  //the-modal-city
</template>

<script>
import '@/assets/styles/components/header.sass'
import socials from '../../assets/scripts/socials'
import { phone } from '../../assets/scripts/variables'
import Storage from '../../assets/scripts/Storage'
import { getMenu, getSearchHints } from '../../assets/scripts/Api'
// import TheModalCity from '../_modals/TheModalCity'

export default {
  name: 'TheHeader',
  data () {
    return {
      basket: null,
      categories: [],
      list: [
        { text: 'Доставка', link: 'Delivery' },
        { text: 'Оплата', link: 'Payment' },
        { text: 'Гарантия', link: 'Guarantee' },
        { text: 'Контакты', link: 'Contacts' }
      ],
      phone,
      socials,
      productsAmount: 100,
      isShowMobileMenu: false,
      query: '',
      isShowMobileSearch: false,
      isShowSearchHints: false,
      searchCategories: [],
      searchProducts: []
    }
  },
  computed: {
    whatsAppLink () {
      return this.socials.find(item => item.name === 'WhatsApp').link
    },
    viberLink () {
      return this.socials.find(item => item.name === 'Viber').link
    }
  },
  methods: {
    goToSearchResultsPage () {
      if (this.query.length > 2) {
        this.$router.push({ name: 'Search', query: { query: this.query } })
      }
    },
    refreshBasket () {
      this.basket = Storage.basket
    },
    clearInput () {
      this.query = ''
      this.isShowMobileSearch = false
    },
    openMobileSearch () {
      this.isShowMobileSearch = true
      this.isShowSearchHints = true
      this.$nextTick(() => {
        this.$refs.input.focus()
        this.getAutocompleteResults()
      })
    },
    getAutocompleteResults () {
      this.isShowSearchHints = true
      const query = this.query
      this.timeOut(query)
    },
    timeOut (query) {
      setTimeout(() => {
        if (query === this.query) {
          this.sendSearchRequest(query)
        }
      }, 300)
    },
    async sendSearchRequest (query) {
      const data = await getSearchHints(query)
      this.searchProducts = data.products || []
      this.searchCategories = data.categories || []
      this.isShowSearchHints = true
    },
    hideSearchHints () {
      this.isShowSearchHints = false
    },
    eventClickInEmpty ({ target }) {
      if (!this.$refs.headerSearch) return
      if (!(this.$refs.headerSearch).contains(target) && !(this.$refs.searchMobileBtn).contains(target)) {
        this.hideSearchHints()
      }
    },
    eventFocusSearch () {
      this.sendSearchRequest(this.query)
    }
  },
  deactivated () {
    window.removeEventListener('storageRefresh', this.refreshBasket)
  },
  mounted () {
    const menu = document.querySelector('#app').dataset.menu
    menu ? this.categories = JSON.parse(menu) : getMenu().then(list => { this.categories = list || [] })
    this.refreshBasket()
    window.addEventListener('storageRefresh', this.refreshBasket)
    window.addEventListener('click', this.eventClickInEmpty)
  },
  unmounted () {
    window.addEventListener('click', this.eventClickInEmpty)
  },
  watch: {
    '$route.query.query' () {
      this.query = this.$route.query.query || ''
    },
    '$route' () {
      this.hideSearchHints()
    }
  }
}

</script>
