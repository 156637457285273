<template lang='pug'>
.pagination
  .pagination__btn-box(v-if="currentPage < pages")
    button.pagination__button(type="button", @click="showMore", :class="{'pagination__button_loading' : isLoadingButton}") {{buttonText}}
    //router-link.pagination__button(:to="{ name: $route.name, params: { page: 'page', numberPage: nextPage}, query: { query: $route.query.query, sort: $route.query.sort, add: true} }") Показать еще
    //router-link.pagination__button(:to="{ ...this.$route, params: { page: 'page', numberPage: nextPage}, add: true }") Показать еще
  ul.pagination__list
    li.pagination__item(v-for="page in pagesArray", :key="page" )
      span.pagination__link(v-if="page === currentPage") {{page}}
      span.pagination__dots(v-else-if="page === '...'") {{page}}
      template(v-else)
        router-link.pagination__link(
            v-if="page === 1",
            :to="{ name: $route.name, query: { query: $route.query.query, sort: $route.query.sort} }",
            @click="updatePage"
          ) {{page}}
        router-link.pagination__link(
            v-else,
            :to="{ name: $route.name, query: { query: $route.query.query, sort: $route.query.sort}, params: { page: 'page', numberPage: page} }"
            @click="updatePage"
          ) {{page}}
</template>

<script>
import '@/assets/styles/components/pagination.sass'

export default {
  name: 'Pagination',
  props: {
    pages: Number,
    currentPage: Number,
    isLoadingButton: Boolean
  },
  computed: {
    pagesArray () {
      if (this.pages <= 7) {
        return Array(this.pages).fill(1).map((number, i) => i + 1)
      } else if (this.currentPage <= 4 && this.currentPage <= this.pages - 4) {
        return [1, 2, 3, 4, 5, '...', this.pages]
      } else if (this.currentPage > 4 && this.currentPage < this.pages - 3) {
        return [1, '...', this.currentPage - 1, this.currentPage, this.currentPage + 1, '...', this.pages]
      } else {
        return [1, '...', this.pages - 4, this.pages - 3, this.pages - 2, this.pages - 1, this.pages]
      }
    },
    nextPage () {
      return this.currentPage + 1
    },
    buttonText () {
      return this.isLoadingButton ? 'Загрузка...' : 'Показать еще'
    }
  },
  methods: {
    showMore () {
      this.$emit('show-more', this.nextPage)
    },
    updatePage (event) {
      const { pathname, search } = event.currentTarget
      const path = search ? `${pathname}${search}` : pathname
      this.$emit('update-page', path)
    }
  }
}
</script>
