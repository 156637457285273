<template lang='pug'>
router-link.tile-big(
  :to="{ name: 'Product', params: {slug: slug} }")
  .tile-big__signs(v-if="priceOld || isHit || isNew" )
    span.tile-big__sign.tile-big__sign_discount(v-if="priceOld") {{ sale }}%
    span.tile-big__sign.tile-big__sign_hit(v-if="isHit") хит
    span.tile-big__sign.tile-big__sign_new(v-if="isNew") new
  picture.tile-big__picture(v-if="image")
    source(:srcset="`${image.w158} 1x, ${image.w316} 2x`" type="image/webp" media="(max-width: 520px)")
    source(:srcset="`${image.w224} 1x, ${image.w448} 2x`" type="image/webp" media="(max-width: 700px)")
    source(:srcset="`${image.w202} 1x, ${image.w404} 2x`" type="image/webp" media="(max-width: 950px)")
    source(:srcset="`${image.w270} 1x, ${image.w540} 2x`" type="image/webp" media="(max-width: 1240px)")
    source(:srcset="`${image.w276} 1x, ${image.w414} 1.5x`" type="image/webp" media="(max-width: 1700px)")
    source(:srcset="`${image.w389} 1x, ${image.w584} 1.5x`" type="image/webp" )
    source(:srcset="`${image.j389} 1x, ${image.j584} 1.5x`")
    img.tile-big__image(:src="`${image.j389}`" alt="" loading="lazy")
  picture.tile-big__picture(v-else)
    img.tile-big__image(src="/images/placeholder-logo.svg", alt="")
  .tile-big__box
    .tile-big__price-line
      span.tile-big__price от {{ price.toLocaleString() }} ₽
      span.tile-big__price-old(v-if="priceOld") {{ priceOld.toLocaleString() }} ₽
    span.tile-big__out-of-stock(v-if="!inStock && !isChiedoProduct && !isAvailableForCart") Нет в наличии
  p.tile-big__text(v-if="name") {{name}}
</template>

<script>
import '@/assets/styles/components/tile-big.sass'

export default {
  name: 'TileBig',
  props: {
    slug: { type: String, required: true },
    name: { type: String, required: true },
    price: { type: Number, required: true },
    priceOld: Number,
    image: { type: Object },
    mod: { type: String },
    isHit: { type: [Number, Boolean] },
    isNew: { type: [Number, Boolean] },
    isChiedoProduct: { type: Boolean },
    inStock: { type: [Number, Boolean], default: true },
    isAvailableForCart: { type: Boolean }
  },
  computed: {
    sale () {
      return Math.ceil(this.price / this.priceOld * 100 - 100) * -1
    }
  }
}
</script>
