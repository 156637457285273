export default [
  {
    name: 'ВКонтакте',
    mod: 'vk',
    link: 'https://vk.com/banketniy_stul'
  },
  {
    name: 'YouTube',
    mod: 'yt',
    link: 'https://www.youtube.com/channel/UCxLKNzyj02mi9mYq3XZHlWw?sub_confirmation=1" '
  },
  {
    name: 'WhatsApp',
    mod: 'wa',
    link: 'https://api.whatsapp.com/send?phone=79229234577'
  },
  {
    name: 'Viber',
    mod: 'vb',
    link: 'viber://pa?chatURI=chiedocover'
  }
]
