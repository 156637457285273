<template lang="pug">
footer.footer
  .footer__cont.cont
    .footer__area
      .footer__info
        span.footer__logo(v-if="$route.name === 'Home'")
          img.footer__image(src="/images/logo.svg" alt="logo", loading="lazy")
        router-link.footer__logo(v-else, :to="{ name: 'Home' }")
          img.footer__image(src="/images/logo.svg" alt="logo", loading="lazy")
        p.footer__descr.text-tiny Стильная мебель для дома
        span.footer__copyright.text-tiny © {{ (new Date()).getFullYear() }} ChiedoHome
        p.footer__notice.text-tiny Изображение товаров на сайте может отличаться от фактического изображения.
      .footer__list
        span.footer__item-subtitle.text(@click="showAccordionItem")  Каталог
        ul.footer__nav-list
          li.footer__nav-item(v-for="category in categories" :key="category.id")
            span.footer__nav-link.text-small(v-if="$route.params.slug === category.slug " ) {{ category.name }}
            router-link.footer__nav-link.link_black-orange.text-small(
              v-else-if="category.type === 'category'"
              :to="{ name: 'Catalog', params: { slug: category.slug } }"
            ) {{ category.name }}
      .footer__list(v-for="(box, i) in menu", :key="i")
        span.footer__item-subtitle.text(@click="showAccordionItem") {{ box.title }}
        ul.footer__nav-list
          li.footer__nav-item(v-for="item in box.list" :key="item.link")
            span.footer__nav-link.text-small(v-if="$route.name === item.link") {{ item.name }}
            router-link.footer__nav-link.link_black-orange.text-small(v-else, :to="{ name: item.link }") {{ item.name }}
      .footer__contacts
        .footer__contacts-item
          a.footer__contacts-link.text.link_black-orange(:href="phone.link") {{ phone.mask }}
          span.footer__contacts-name.footer__contacts-name_weekdays.text-tiny {{worktime.weekdays}}
          span.footer__contacts-name.text-tiny {{worktime.weekends}}
        .footer__contacts-item
          a.footer__contacts-link.text.link_black-orange(:href="mail.link") {{ mail.mask }}
          span.footer__contacts-name.text-tiny Отдел продаж
        ul.footer__social-list
          li.footer__social-item(v-for="social in socials" :key="social.mod")
            a.footer__social-link(:href="social.link" :class="`footer__social-link_${social.mod}`")
      button.footer__scroll-top(type="button", @click="scrollToTop")
</template>

<script>
import '@/assets/styles/components/footer.sass'
import { getMenu } from '../../assets/scripts/Api'
import socials from '../../assets/scripts/socials'
import { phone, mail, worktime } from '../../assets/scripts/variables'

export default {
  name: 'TheFooter',
  data () {
    return {
      socials,
      phone,
      mail,
      worktime,
      categories: [],
      menu: [
        {
          title: 'Покупателям',
          list: [
            { name: 'Оплата', link: 'Payment' },
            { name: 'Доставка', link: 'Delivery' },
            { name: 'Гарантия', link: 'Guarantee' },
            { name: 'Возврат', link: 'Recovery' }
          ]
        }, {
          title: 'Компания',
          list: [
            { name: 'Политика конфиденциальности', link: 'Policy' },
            { name: 'Адреса магазинов', link: 'Contacts' },
            { name: 'О компании', link: 'About' },
            { name: 'Реквизиты', link: 'Requisites' }
          ]
        }
      ],
      activeIndex: null
    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    showAccordionItem (evt) {
      evt.currentTarget.classList.toggle('footer__item-subtitle_active')
    }
  },
  mounted () {
    const menu = document.querySelector('#app').dataset.menu
    menu ? this.categories = JSON.parse(menu) : getMenu().then(list => { this.categories = list || [] })
  }
}
</script>
