class Storage {
  #hash = 'VG4C2Z'
  getSS (name) {
    const item = sessionStorage.getItem(`${this.#hash}_${name}`)
    if (item) return JSON.parse(item)
    return null
  }

  setSS (name, data) {
    sessionStorage.setItem(`${this.#hash}_${name}`, JSON.stringify(data))
  }

  refresh () {
    const event = new Event('storageRefresh')
    window.dispatchEvent(event)
  }

  get user () {
    return this.getSS('user')
  }

  set user (data) {
    this.setSS('user', data)
    this.refresh()
  }

  get showThanks () {
    return this.getSS('thanks')
  }

  set showThanks (data) {
    this.setSS('thanks', data)
  }

  get basket () {
    return this.getSS('basket')
  }

  set basket (data) {
    this.setSS('basket', data)
    this.refresh()
  }

  get menu () { return this.getSS('menu') }
  set menu (data) { this.setSS('menu', data) }

  get prodsSaleForTheHome () { return this.getSS('prods-sale-for-the-home') }
  set prodsSaleForTheHome (data) { this.setSS('prods-sale-for-the-home', data) }

  get prodsPopForTheHome () { return this.getSS('prods-pop-for-the-home') }
  set prodsPopForTheHome (data) { this.setSS('prods-pop-for-the-home', data) }

  get prodsPopularForBasket () { return this.getSS('prods-pop-for-the-basket') }
  set prodsPopularForBasket (data) { this.setSS('prods-pop-for-the-basket', data) }

  get categoriesPopular () {
    return this.getSS('categories-popular')
  }

  set categoriesPopular (data) {
    this.setSS('categories-popular', data)
  }

  getProdsInStock (page) {
    return this.getSS(`prods-in-stock-${page}`)
  }

  setProdsInStock (page, data) {
    this.setSS(`prods-in-stock-${page}`, data)
  }

  getProdsDesignersChoice (page) {
    return this.getSS(`prods-designers-choice-${page}`)
  }

  setProdsDesignersChoice (page, data) {
    this.setSS(`prods-designers-choice-${page}`, data)
  }

  getCategory (slug) {
    return this.getSS(`c-${slug}`)
  }

  setCategory (slug, data) {
    return this.setSS(`c-${slug}`, data)
  }

  getProductsCategory (slug, page) {
    return this.getSS(`category-${slug}-${page}`)
  }

  setProductsCategory (slug, page, data) {
    return this.setSS(`category-${slug}-${page}`, data)
  }

  getProductsSubcategory (slug, page) {
    return this.getSS(`subcategory-${slug}-${page}`)
  }

  setProductsSubcategory (slug, page, data) {
    return this.setSS(`subcategory-${slug}-${page}`, data)
  }

  getProduct (slug) {
    return this.getSS(`product-${slug}`)
  }

  setProduct (slug, data) {
    return this.setSS(`product-${slug}`, data)
  }

  get banners () {
    return this.getSS('banners')
  }

  set banners (data) {
    this.setSS('banners', data)
  }

  getMoreProducts (slug) {
    return this.getSS(`more-products-${slug}`)
  }

  setMoreProducts (slug, data) {
    return this.setSS(`more-products-${slug}`, data)
  }
}

export default new Storage()
