import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  }, {
    path: '/about',
    name: 'About',
    component: () => import('../views/AboutPage.vue')
  }, {
    path: '/basket',
    name: 'Basket',
    component: () => import('../views/BasketPage.vue')
  }, {
    path: '/category/:slug/:page(page)?/:numberPage(\\d+)?',
    name: 'Catalog',
    component: () => import('../views/CatalogPage.vue')
  }, {
    path: '/delivery',
    name: 'Delivery',
    component: () => import('../views/DeliveryPage.vue')
  }, {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPasswordPage.vue')
  }, {
    path: '/search/:page(page)?/:numberPage(\\d+)?',
    name: 'Search',
    component: () => import('../views/SearchPage.vue')
  }, {
    path: '/thanks',
    name: 'Thanks',
    component: () => import('../views/ThanksPage.vue')
  }, {
    path: '/guarantee',
    name: 'Guarantee',
    component: () => import('../views/GuaranteePage.vue')
  }, {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../views/ContactsPage.vue')
  }, {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginPage.vue')
  }, {
    path: '/registration',
    name: 'Registration',
    component: () => import('../views/RegistrationPage.vue')
  }, {
    path: '/recovery',
    name: 'Recovery',
    component: () => import('../views/RecoveryPage.vue')
  }, {
    path: '/designers-choice/:page(page)?/:numberPage(\\d+)?',
    name: 'DesignersChoice',
    component: () => import('../views/DesignersChoicePage.vue')
  }, {
    path: '/products/:slug',
    name: 'Product',
    component: () => import('../views/ProductPage.vue')
  }, {
    path: '/products-in-stock/:page(page)?/:numberPage(\\d+)?',
    name: 'ProductsInStock',
    component: () => import('../views/ProductsInStockPage.vue')
  }, {
    path: '/payment',
    name: 'Payment',
    component: () => import('../views/PaymentPage.vue')
  }, {
    path: '/policy',
    name: 'Policy',
    component: () => import('../views/PolicyPage.vue')
  }, {
    path: '/requisites',
    name: 'Requisites',
    component: () => import('../views/RequisitesPage.vue')
  }, {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: () => import('../views/VerifyEmailPage')
  }, {
    path: '/user-info',
    name: 'UserInfo',
    component: () => import('../views/UserInfoPage.vue')
  }, {
    path: '/:pathMatch(.*)*',
    name: 'Error',
    component: () => import('../views/ErrorPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (!to.query.add) return { top: 0 }
  }
})

export default router
// actions* -> products-in-stock*
// popular* -> designers-choice*
