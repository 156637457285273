<template lang="pug">
head-meta(title-page="CHIEDOHOME - сеть магазинов мебели для дома", description="Столы, стулья и другие товары для дома в магазинах CHIEDOHOME. Широкий ассортимент моделей в наличии и под заказ. Фото, характеристики, актуальные цены. Гарантия качества от производителя")
informer(:big-banners="topBigBanners", :small-banners="topSmallBanners")
category-tile(:categories="categories")
tile-line(block-name="Товары в наличии", :products="productsSale", :moreRoute = "{ name: 'ProductsInStock' }")
informer-line(v-if="bottomSmallBanners.length", :banners="bottomSmallBanners")
tile-line(block-name="Выбор дизайнеров", :products="productsPopular", :moreRoute = "{ name: 'DesignersChoice' }")
</template>

<script>
import Informer from '../components/home/Informer'
import CategoryTile from '../components/home/CategoryTile'
import TileLine from '../components/_tiles/TileLine'
import InformerLine from '../components/InformerLine'
import {
  getBanners,
  getProductsInStockForTheHome,
  getProductsDesignersChoiceForTheHome,
  getCategoriesPopular
} from '../assets/scripts/Api'
import HeadMeta from '../components/_layout/HeadMeta'

export default {
  name: 'HomePage',
  components: { HeadMeta, Informer, CategoryTile, TileLine, InformerLine },
  data () {
    return {
      productsSale: [],
      productsPopular: [],
      banners: {},
      categories: [],
      topBigBanners: [],
      topSmallBanners: [],
      bottomSmallBanners: []
    }
  },
  methods: {
    placeBanners (banners) {
      this.banners = banners
      this.topSmallBanners = this.banners.small.splice(0, 2)
      this.topBigBanners = this.banners.big
      this.bottomSmallBanners = this.banners.small.splice(0, 2)
    }
  },
  mounted () {
    const banners = document.querySelector('#app').dataset.banners
    banners ? this.placeBanners(JSON.parse(banners)) : getBanners().then(data => { this.placeBanners(data) })
    getProductsInStockForTheHome().then(data => {
      if (data) this.productsSale = data
    })
    getProductsDesignersChoiceForTheHome().then(data => {
      if (data) this.productsPopular = data
    })
    getCategoriesPopular().then(data => {
      if (data) this.categories = data.categories
    })
  }
}
</script>
